@import "../node_modules/bootstrap/scss/_functions.scss";

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$primary: $gray-100;
$secondary: $gray-900;

$font-family-brand: 'raleway', Arial;

$body-bg: $secondary;
$body-color: $primary;

$link-decoration: underline;
$link-hover-decoration: underline;
$btn-border-radius: 0;
$card-bg: none;

@import "../node_modules/bootstrap/scss/_variables.scss";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "sidebar.scss";
@import "fonts.scss";

#wrapper {
    height: 100%;
}

#page-content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.content {
    flex: 1 0 auto;
}

#scroll-top {
    position: fixed;
    right: 2rem;
    bottom: 1rem;
    padding: 0.5rem;
}

#menu-toggle {
    position: fixed;
    left: 2rem;
    top: 1rem;
    padding: 0.5rem;
    z-index: 1000;
}

#top {
    background-color: $black;
    a {
        color: $white;
        &:hover {
            color:$gray-300;
        }
    }
}

.brand-header {
    font-size: 3rem;
    font-family: $font-family-brand;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

h1 {
    text-align:center;
    margin-bottom: 1rem;
}

@media(max-width:768px) {
    #scroll-top {
        right: 1rem;
    }

    #menu-toggle {
        left: 1rem;
    }
}

@media(max-width:476px) {
    .brand-header {
        font-size: 1.8rem;
    }
}

.font-semibold {
    font-weight: 600;
}

.font-light {
    font-weight: 400;
}

.font-thin {
    font-weight: 300;
}

.text-justify{
    text-align:justify;
}

.icon-circled {
    font-size: 3em;
    border-radius: 50%;
    padding: 1rem;
}

textarea {
    overflow: hidden;
}

pre.prettyprint {
    background-color: $white;
    color: $gray-900;
    border: none !important;
    padding: 1rem !important;
}

time {
    background-color: $white;
    padding: 8px;
    display: block;
    color: $gray-900;
    float: left;
    margin-right: 20px;
    text-align: left;
    position: relative;

    span {
        display: block;
        padding: 0;
        margin: 0;
        line-height: .9;
        text-align: left;
    }

    .month {
        padding-left: 20px;
    }

    .day {
        padding-left: 23px;
    }

    .year {
        transform: rotate(-90deg);
        color: #eee;
        background-color: #c70039;
        position: absolute;
        left: -16px;
        bottom: 15px;
        padding: 5px;
        margin-top: -10px;
    }
}

.hr {
    height: 2px;
    margin: 20px auto;
    width: 33%;
    background-color: $primary;
}

.pager {
    padding: 15px;
    font-size: 1.2em;
    color: $white;
    text-decoration: none;
    background-color: $black;
    max-width: 50%;
    margin-bottom: 15px;

    &.prev {
        float: left;
    }

    &.next {
        float: right;
    }
}

footer {
    text-align: center;
    color: $secondary;
    flex-shrink: 0;

    .icon {
        font-size: 3em;
        color: $primary;
    }

    #footer-social-links {
        h2 {
            color: $primary;
        }

        margin-top: 20px;
        clear: both;
    }

    .footer {
        background-color: $black;
        color: $white;
    }
}