﻿$sidebar-width: 150px;

body {
    overflow-x: hidden;
 }

#wrapper {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled {
    padding-left: $sidebar-width;
}

#sidebar-wrapper {
    z-index: 1000;
    position: fixed;
    left: $sidebar-width;
    width: 0;
    height: 100%;
    margin-left: -$sidebar-width;
    overflow-y: auto;
    background: $black;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
    width: $sidebar-width;
}

#page-content-wrapper {
    width: 100%;
    position: absolute;
}

#wrapper.toggled #page-content-wrapper {
    position: absolute;
    margin-right: -$sidebar-width;
}

.sidebar-nav {
    position: absolute;
    top: 0;
    width: $sidebar-width;
    margin: 0;
    padding: 0;
    list-style: none;
    padding-top: 60px;
}

.sidebar-nav li {
    text-indent: 20px;
    line-height: 40px;
    a{
        color:$white;
    }
}

@media(min-width:768px) {
    #wrapper {
        padding-left: 0;
    }

    #wrapper.toggled {
        padding-left: $sidebar-width;
    }

    #sidebar-wrapper {
        width: 0;
    }

    #wrapper.toggled #sidebar-wrapper {
        width: $sidebar-width;
    }

    #page-content-wrapper {
        position: relative;
    }

    #wrapper.toggled #page-content-wrapper {
        position: relative;
        margin-right: 0;
    }
}