﻿@charset "UTF-8";
$font-path: "../public/fonts/";

@font-face {
  font-family: 'fontello';
  src: url('#{$font-path}fontello.eot?11408660');
  src: url('#{$font-path}fontello.eot?11408660#iefix') format('embedded-opentype'), url('#{$font-path}fontello.woff?11408660') format('woff'), url('#{$font-path}fontello.ttf?11408660') format('truetype'), url('#{$font-path}fontello.svg?11408660#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?99896549#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: never;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-search:before {
    content: '\e800';
}
/* '' */
.icon-up-open:before {
    content: '\e801';
}
/* '' */
.icon-tag:before {
    content: '\e802';
}
/* '' */
.icon-left-open:before {
    content: '\e803';
}
/* '' */
.icon-down-open:before {
    content: '\e804';
}
/* '' */
.icon-right-open:before {
    content: '\e805';
}
/* '' */
.icon-info-circled:before {
    content: '\e806';
}
/* '' */
.icon-link-ext:before {
    content: '\f08e';
}
/* '' */
.icon-twitter:before {
    content: '\f099';
}
/* '' */
.icon-menu:before {
    content: '\f0c9';
}
/* '' */
.icon-linkedin:before {
    content: '\f0e1';
}
/* '' */
.icon-mobile:before {
    content: '\f10b';
}
/* '' */
.icon-gamepad:before {
    content: '\f11b';
}
/* '' */
.icon-code:before {
    content: '\f121';
}
/* '' */
.icon-info:before {
    content: '\f129';
}
/* '' */
.icon-html5:before {
    content: '\f13b';
}
/* '' */
.icon-git-squared:before {
    content: '\f1d2';
}
/* '' */
.icon-git:before {
    content: '\f1d3';
}
/* '' */
.icon-twitter-squared:before {
    content: '\f304';
}
/* '' */
.icon-linkedin-squared:before {
    content: '\f30c';
}
/* '' */
@font-face {
  font-family: 'ralewayextralight';
  src: url('#{$font-path}raleway-extralight-webfont.eot');
  src: url('#{$font-path}raleway-extralight-webfont.eot?#iefix') format('embedded-opentype'), url('#{$font-path}raleway-extralight-webfont.woff2') format('woff2'), url('#{$font-path}raleway-extralight-webfont.woff') format('woff'), url('#{$font-path}raleway-extralight-webfont.ttf') format('truetype'), url('#{$font-path}raleway-extralight-webfont.svg#ralewayextralight') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ralewaylight';
  src: url('#{$font-path}raleway-light-webfont.eot');
  src: url('#{$font-path}raleway-light-webfont.eot?#iefix') format('embedded-opentype'), url('#{$font-path}raleway-light-webfont.woff2') format('woff2'), url('#{$font-path}raleway-light-webfont.woff') format('woff'), url('#{$font-path}raleway-light-webfont.ttf') format('truetype'), url('#{$font-path}raleway-light-webfont.svg#ralewaylight') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ralewayregular';
  src: url('#{$font-path}raleway-regular-webfont.eot');
  src: url('#{$font-path}raleway-regular-webfont.eot?#iefix') format('embedded-opentype'), url('#{$font-path}raleway-regular-webfont.woff2') format('woff2'), url('#{$font-path}raleway-regular-webfont.woff') format('woff'), url('#{$font-path}raleway-regular-webfont.ttf') format('truetype'), url('#{$font-path}raleway-regular-webfont.svg#ralewayregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ralewaysemibold';
  src: url('#{$font-path}raleway-semibold-webfont.eot');
  src: url('#{$font-path}raleway-semibold-webfont.eot?#iefix') format('embedded-opentype'), url('#{$font-path}raleway-semibold-webfont.woff2') format('woff2'), url('#{$font-path}raleway-semibold-webfont.woff') format('woff'), url('#{$font-path}raleway-semibold-webfont.ttf') format('truetype'), url('#{$font-path}raleway-semibold-webfont.svg#ralewaysemibold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ralewaybold';
  src: url('#{$font-path}raleway-bold-webfont.eot');
  src: url('#{$font-path}raleway-bold-webfont.eot?#iefix') format('embedded-opentype'), url('#{$font-path}raleway-bold-webfont.woff2') format('woff2'), url('#{$font-path}raleway-bold-webfont.woff') format('woff'), url('#{$font-path}raleway-bold-webfont.ttf') format('truetype'), url('#{$font-path}raleway-bold-webfont.svg#ralewaybold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ralewayextrabold';
  src: url('#{$font-path}raleway-extrabold-webfont.eot');
  src: url('#{$font-path}raleway-extrabold-webfont.eot?#iefix') format('embedded-opentype'), url('#{$font-path}raleway-extrabold-webfont.woff2') format('woff2'), url('#{$font-path}raleway-extrabold-webfont.woff') format('woff'), url('#{$font-path}raleway-extrabold-webfont.ttf') format('truetype'), url('#{$font-path}raleway-extrabold-webfont.svg#ralewayextrabold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ralewayheavy';
  src: url('#{$font-path}raleway-heavy-webfont.eot');
  src: url('#{$font-path}raleway-heavy-webfont.eot?#iefix') format('embedded-opentype'), url('#{$font-path}raleway-heavy-webfont.woff2') format('woff2'), url('#{$font-path}raleway-heavy-webfont.woff') format('woff'), url('#{$font-path}raleway-heavy-webfont.ttf') format('truetype'), url('#{$font-path}raleway-heavy-webfont.svg#ralewayheavy') format('svg');
  font-weight: normal;
  font-style: normal;
}
/*# sourceMappingURL=fonts.css.map */